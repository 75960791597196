import React, { forwardRef, useState } from 'react';
import { Flex, Box, Image, Tooltip, Tag, Spacer } from '@chakra-ui/core';
import { Text } from '@typography';
import { colors } from '@theme';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';

const RoundedCheck = ({ bgColor, checkColor }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="22" height="22" rx="11" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3293 6.62372C16.5371 6.80556 16.5581 7.12144 16.3763 7.32926L9.37629 15.3293C9.28516 15.4334 9.15495 15.4951 9.01664 15.4997C8.87833 15.5043 8.7443 15.4514 8.64645 15.3536L5.64645 12.3536C5.45118 12.1583 5.45118 11.8417 5.64645 11.6465C5.84171 11.4512 6.15829 11.4512 6.35355 11.6465L8.97564 14.2685L15.6237 6.67075C15.8056 6.46294 16.1214 6.44188 16.3293 6.62372Z"
        fill={checkColor}
      />
    </svg>
  );
};

const HelpIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
        fill="#CACACA"
      />
    </svg>
  );
};

const SoloIcon = () => (
  <svg
    width="43"
    height="32"
    viewBox="0 0 43 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8392 7.35395C18.8392 11.0327 15.8823 14.0121 12.2382 14.0121C8.59414 14.0121 5.63723 11.0327 5.63723 7.35395C5.63723 3.67522 8.59414 0.695827 12.2382 0.695827C15.8823 0.695827 18.8392 3.67522 18.8392 7.35395Z"
      fill="#6EDAF2"
      stroke="black"
      strokeWidth="0.391654"
    />
    <path
      d="M37.5267 7.35395C37.5267 11.0327 34.5698 14.0121 30.9257 14.0121C27.2816 14.0121 24.3247 11.0327 24.3247 7.35395C24.3247 3.67522 27.2816 0.695827 30.9257 0.695827C34.5698 0.695827 37.5267 3.67522 37.5267 7.35395Z"
      fill="#F2D022"
      stroke="black"
      strokeWidth="0.391654"
    />
    <path
      d="M23.8844 26.5041C23.8844 27.895 23.5552 28.8655 22.9837 29.5505C22.4109 30.237 21.5738 30.662 20.5078 30.9192C19.4405 31.1766 18.1612 31.2617 16.724 31.281C15.8964 31.2921 15.0265 31.2816 14.119 31.2706C13.446 31.2624 12.7524 31.254 12.0401 31.254C11.3278 31.254 10.6342 31.2624 9.96122 31.2706C9.05373 31.2816 8.1838 31.2921 7.35618 31.281C5.91898 31.2617 4.63972 31.1766 3.57238 30.9192C2.50639 30.662 1.66926 30.237 1.09654 29.5505C0.525022 28.8655 0.195827 27.895 0.195827 26.5041C0.195827 20.9529 5.46884 16.4029 12.0401 16.4029C18.6114 16.4029 23.8844 20.9529 23.8844 26.5041Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.391654"
    />
    <path
      d="M42.578 26.5041C42.578 27.8957 42.2539 28.8667 41.6916 29.5517C41.1284 30.2378 40.3055 30.6624 39.2576 30.9193C38.2082 31.1767 36.9501 31.2617 35.5362 31.281C34.722 31.2921 33.8663 31.2816 32.9735 31.2706C32.3114 31.2624 31.629 31.254 30.9279 31.254C30.2269 31.254 29.5445 31.2624 28.8824 31.2706C27.9896 31.2816 27.1339 31.2921 26.3197 31.281C24.9057 31.2617 23.6477 31.1767 22.5982 30.9193C21.5504 30.6624 20.7275 30.2378 20.1643 29.5517C19.602 28.8667 19.2779 27.8957 19.2779 26.5041C19.2779 20.9502 24.4671 16.4029 30.9279 16.4029C37.3888 16.4029 42.578 20.9502 42.578 26.5041Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.391654"
    />
  </svg>
);

const ProIcon = () => (
  <svg
    width="49"
    height="31"
    viewBox="0 0 49 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5625 6.80178C23.5625 10.3938 20.6753 13.303 17.1171 13.303C13.5589 13.303 10.6717 10.3938 10.6717 6.80178C10.6717 3.20976 13.5589 0.300586 17.1171 0.300586C20.6753 0.300586 23.5625 3.20976 23.5625 6.80178Z"
      fill="#6EDAF2"
      stroke="black"
      strokeWidth="0.382423"
    />
    <path
      d="M39.1875 6.80178C39.1875 10.3938 36.3003 13.303 32.7421 13.303C29.1839 13.303 26.2967 10.3938 26.2967 6.80178C26.2967 3.20976 29.1839 0.300586 32.7421 0.300586C36.3003 0.300586 39.1875 3.20976 39.1875 6.80178Z"
      fill="#F2D022"
      stroke="black"
      strokeWidth="0.382423"
    />
    <path
      d="M28.4894 25.402C28.4894 26.7601 28.168 27.7078 27.6099 28.3767C27.0507 29.0469 26.2333 29.462 25.1924 29.713C24.1502 29.9644 22.9011 30.0474 21.4978 30.0663C20.6897 30.0772 19.8403 30.0669 18.9542 30.0562C18.2971 30.0482 17.6198 30.04 16.9243 30.04C16.2288 30.04 15.5515 30.0482 14.8944 30.0562C14.0083 30.0669 13.1589 30.0772 12.3508 30.0663C10.9474 30.0474 9.69834 29.9644 8.65615 29.713C7.61529 29.462 6.79789 29.0469 6.23866 28.3767C5.68062 27.7078 5.35918 26.7601 5.35918 25.402C5.35918 19.9817 10.5079 15.5389 16.9243 15.5389C23.3407 15.5389 28.4894 19.9817 28.4894 25.402Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.382423"
    />
    <path
      d="M44.118 25.402C44.118 26.7608 43.8015 27.7089 43.2524 28.3778C42.7025 29.0478 41.899 29.4623 40.8758 29.7132C39.8511 29.9644 38.6227 30.0474 37.2422 30.0663C36.4471 30.0772 35.6116 30.0669 34.7398 30.0562C34.0934 30.0482 33.427 30.04 32.7425 30.04C32.058 30.04 31.3916 30.0482 30.7451 30.0562C29.8734 30.0669 29.0379 30.0772 28.2428 30.0663C26.8622 30.0474 25.6338 29.9644 24.6091 29.7132C23.586 29.4623 22.7825 29.0478 22.2325 28.3778C21.6835 27.7089 21.367 26.7608 21.367 25.402C21.367 19.979 26.4339 15.5389 32.7425 15.5389C39.051 15.5389 44.118 19.979 44.118 25.402Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.382423"
    />
    <circle
      cx="41.1945"
      cy="15.7355"
      r="4.0367"
      fill="#98D3B6"
      stroke="black"
      strokeWidth="0.424916"
    />
    <path
      d="M48.4174 27.492C48.4174 28.3491 48.2174 28.9343 47.8815 29.3423C47.5446 29.7516 47.0484 30.0101 46.4015 30.1683C45.7529 30.3268 44.9714 30.38 44.0854 30.3921C43.5746 30.399 43.0401 30.3925 42.4813 30.3856C42.0657 30.3805 41.6366 30.3752 41.1938 30.3752C40.7511 30.3752 40.322 30.3805 39.9064 30.3856C39.3476 30.3925 38.8131 30.399 38.3023 30.3921C37.4163 30.38 36.6348 30.3268 35.9862 30.1683C35.3393 30.0101 34.8431 29.7516 34.5062 29.3423C34.1703 28.9343 33.9703 28.3491 33.9703 27.492C33.9703 24.0773 37.1748 21.2593 41.1938 21.2593C45.2129 21.2593 48.4174 24.0773 48.4174 27.492Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.424916"
    />
    <circle
      cx="8.10072"
      cy="15.7355"
      r="4.0367"
      fill="#98D3B6"
      stroke="black"
      strokeWidth="0.424916"
    />
    <path
      d="M15.3237 27.492C15.3237 28.3491 15.1236 28.9343 14.7877 29.3423C14.4508 29.7516 13.9546 30.0101 13.3077 30.1683C12.6591 30.3268 11.8777 30.38 10.9916 30.3921C10.4808 30.399 9.94636 30.3925 9.3875 30.3856C8.97193 30.3805 8.54289 30.3752 8.10009 30.3752C7.6573 30.3752 7.22826 30.3805 6.81269 30.3856C6.25383 30.3925 5.71934 30.399 5.20855 30.3921C4.3225 30.38 3.54107 30.3268 2.89248 30.1683C2.24556 30.0101 1.74936 29.7516 1.41245 29.3423C1.07655 28.9343 0.876521 28.3491 0.876521 27.492C0.876521 24.0773 4.08108 21.2593 8.10009 21.2593C12.1191 21.2593 15.3237 24.0773 15.3237 27.492Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.424916"
    />
  </svg>
);

const MaxIcon = () => (
  <svg
    width="49"
    height="31"
    viewBox="0 0 49 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4084 6.85285C23.4084 10.4723 20.4992 13.4037 16.9138 13.4037C13.3285 13.4037 10.4192 10.4723 10.4192 6.85285C10.4192 3.23342 13.3285 0.302046 16.9138 0.302046C20.4992 0.302046 23.4084 3.23342 23.4084 6.85285Z"
      fill="#6EDAF2"
      stroke="black"
      strokeWidth="0.385341"
    />
    <path
      d="M39.1545 6.85285C39.1545 10.4723 36.2453 13.4037 32.6599 13.4037C29.0746 13.4037 26.1653 10.4723 26.1653 6.85285C26.1653 3.23342 29.0746 0.302046 32.6599 0.302046C36.2453 0.302046 39.1545 3.23342 39.1545 6.85285Z"
      fill="#F2D022"
      stroke="black"
      strokeWidth="0.385341"
    />
    <path
      d="M28.3744 25.5939C28.3744 26.9624 28.0505 27.9174 27.4882 28.5913C26.9247 29.2667 26.1011 29.6849 25.0523 29.9379C24.0021 30.1912 22.7435 30.2749 21.3295 30.2939C20.5152 30.3048 19.6593 30.2945 18.7664 30.2837C18.1043 30.2756 17.4219 30.2674 16.721 30.2674C16.0202 30.2674 15.3378 30.2756 14.6757 30.2837C13.7828 30.2945 12.9269 30.3048 12.1126 30.2939C10.6986 30.2749 9.43994 30.1912 8.3898 29.9379C7.34099 29.6849 6.51736 29.2667 5.95386 28.5913C5.39156 27.9174 5.06767 26.9624 5.06767 25.5939C5.06767 20.1323 10.2557 15.6556 16.721 15.6556C23.1864 15.6556 28.3744 20.1323 28.3744 25.5939Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.385341"
    />
    <path
      d="M44.1212 25.5939C44.1212 26.9631 43.8023 27.9185 43.249 28.5925C42.6949 29.2675 41.8853 29.6853 40.8543 29.9381C39.8218 30.1912 38.584 30.2749 37.1929 30.2939C36.3918 30.3048 35.5498 30.2945 34.6715 30.2837C34.0201 30.2756 33.3486 30.2674 32.6589 30.2674C31.9692 30.2674 31.2977 30.2756 30.6463 30.2837C29.7679 30.2945 28.926 30.3048 28.1249 30.2939C26.7338 30.2749 25.496 30.1912 24.4634 29.9381C23.4325 29.6853 22.6229 29.2675 22.0687 28.5925C21.5155 27.9185 21.1966 26.9631 21.1966 25.5939C21.1966 20.1296 26.3022 15.6556 32.6589 15.6556C39.0156 15.6556 44.1212 20.1296 44.1212 25.5939Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.385341"
    />
    <circle
      cx="41.1722"
      cy="15.8558"
      r="4.06751"
      fill="#98D3B6"
      stroke="black"
      strokeWidth="0.428159"
    />
    <path
      d="M48.4512 27.7033C48.4512 28.5669 48.2496 29.1566 47.9111 29.5677C47.5717 29.9801 47.0717 30.2406 46.4198 30.4C45.7663 30.5598 44.9789 30.6133 44.0861 30.6255C43.5714 30.6325 43.0328 30.6259 42.4697 30.619C42.0509 30.6138 41.6186 30.6085 41.1725 30.6085C40.7263 30.6085 40.294 30.6138 39.8752 30.619C39.3121 30.6259 38.7735 30.6325 38.2589 30.6255C37.366 30.6133 36.5787 30.5598 35.9251 30.4C35.2733 30.2406 34.7733 29.9801 34.4338 29.5677C34.0953 29.1566 33.8938 28.5669 33.8938 27.7033C33.8938 24.2625 37.1228 21.4231 41.1725 21.4231C45.2221 21.4231 48.4512 24.2625 48.4512 27.7033Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.428159"
    />
    <circle
      cx="24.8714"
      cy="15.8558"
      r="4.06751"
      fill="#98D3B6"
      stroke="black"
      strokeWidth="0.428159"
    />
    <path
      d="M32.1504 27.7033C32.1504 28.5669 31.9488 29.1566 31.6104 29.5677C31.2709 29.9801 30.7709 30.2406 30.119 30.4C29.4655 30.5598 28.6781 30.6133 27.7853 30.6255C27.2706 30.6325 26.732 30.6259 26.1689 30.619C25.7502 30.6138 25.3179 30.6085 24.8717 30.6085C24.4255 30.6085 23.9932 30.6138 23.5745 30.619C23.0113 30.6259 22.4728 30.6325 21.9581 30.6255C21.0653 30.6133 20.2779 30.5598 19.6243 30.4C18.9725 30.2406 18.4725 29.9801 18.133 29.5677C17.7945 29.1566 17.593 28.5669 17.593 27.7033C17.593 24.2625 20.822 21.4231 24.8717 21.4231C28.9214 21.4231 32.1504 24.2625 32.1504 27.7033Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.428159"
    />
    <circle
      cx="7.82846"
      cy="15.8558"
      r="4.06751"
      fill="#98D3B6"
      stroke="black"
      strokeWidth="0.428159"
    />
    <path
      d="M15.1074 27.7033C15.1074 28.5669 14.9059 29.1566 14.5674 29.5677C14.2279 29.9801 13.7279 30.2406 13.0761 30.4C12.4225 30.5598 11.6351 30.6133 10.7423 30.6255C10.2276 30.6325 9.68906 30.6259 9.12594 30.619C8.7072 30.6138 8.27488 30.6085 7.82872 30.6085C7.38255 30.6085 6.95023 30.6138 6.53149 30.619C5.96837 30.6259 5.4298 30.6325 4.91511 30.6255C4.0223 30.6133 3.2349 30.5598 2.58136 30.4C1.92951 30.2406 1.42951 29.9801 1.09003 29.5677C0.751568 29.1566 0.550017 28.5669 0.550017 27.7033C0.550017 24.2625 3.77903 21.4231 7.82872 21.4231C11.8784 21.4231 15.1074 24.2625 15.1074 27.7033Z"
      fill="#E4E4E4"
      stroke="black"
      strokeWidth="0.428159"
    />
  </svg>
);

const TableRowContent = ({ text = null, icon = null, color }) => {
  return (
    <>
      <DesktopOnly>
        <Flex
          direction="row"
          justifyContent="center"
          style={{
            borderLeft: `1px solid ${color}`,
            borderRight: `1px solid ${color}`,
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {text?.length && (
            <Text align="center" bold size={16}>
              {text}
            </Text>
          )}
          {icon && icon}
        </Flex>
      </DesktopOnly>
      <MobileOnly>
        <Flex
          direction="row"
          justify="center"
          align="center"
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
            textAlign: 'center',
          }}
        >
          {text?.length && (
            <Text align="center" bold size={12}>
              {text}
            </Text>
          )}
          {icon && icon}
        </Flex>
      </MobileOnly>
    </>
  );
};

const TableData = ({ children }) => {
  return <td style={{ paddingLeft: '0.75rem', paddingright: '0.75rem' }}>{children}</td>;
};

const tools = [
  {
    title: 'Envelope system',
    tooltip: <Text size={14}>Easily manage your bills & goals using envelopes</Text>,
  },
  {
    title: 'Zeta Pay',
    tooltip: <Text size={14}>Instant P2P payments to anyone using Zeta</Text>,
  },
  {
    title: 'Concierge',
    tooltip: <Text size={14}>AI + human help when you need it</Text>,
  },
  {
    title: 'Digital banking tools',
    tooltip: (
      <Text size={14} color={colors.iceberg}>
        Useful tools like checks*, digital wallets, ATMs and more
      </Text>
    ),
  },
  {
    title: 'Access to VIP program',
    isHtml: true,
    tooltip: (
      <Text size={14}>
        Unlock an APY<sup>2</sup> boost & special perks**
      </Text>
    ),
  },
];

const ToolTipCopy = forwardRef(({ children, ...rest }, ref) => (
  <Box p="1">
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  </Box>
));

const CustomTooltip = ({ copy, openTooltipCopy, setOpenTooltipCopy }) => {
  return (
    <div
      onMouseEnter={() => setOpenTooltipCopy(copy)}
      onMouseLeave={() => setOpenTooltipCopy(null)}
    >
      <Tooltip
        isOpen={copy === openTooltipCopy}
        label={
          <Flex style={{ padding: '0.5rem 1rem' }}>
            {typeof copy === 'string' ? <Text size={14}>{copy}</Text> : copy}
          </Flex>
        }
        isDisabled
        background={'#333'}
        borderRadius={12}
        hasArrow
      >
        <ToolTipCopy>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setOpenTooltipCopy(copy);
            }}
          >
            <HelpIcon />
          </a>
        </ToolTipCopy>
      </Tooltip>
    </div>
  );
};

const products = {
  free: {
    icon: <SoloIcon />,
    bg: colors.blueLight,
    title: 'FREE',
    amount: '$0.00',
    yearAmount: '$0.00',
    blurb1: 'Great for couples to easily share finances or',
    blurb2: 'for parents to manage money with a teen',
  },
  pro: {
    icon: <ProIcon />,
    bg: colors.yellowLight,
    title: 'ZETA+',
    amount: '$9.99',
    yearAmount: '$6.99',
    blurb1: 'Great for families who want more accounts',
    blurb2: 'or want to automate their banking',
  },
  max: {
    icon: <MaxIcon />,
    bg: colors.shared,
    title: 'ZETA+ MAX',
    amount: '$X.99',
    yearAmount: '$X.99',
    blurb1: 'Great for large or extended families to',
    blurb2: 'manage money under one subscription',
  },
};

const ProductBlock = ({ type, frequency }) => (
  <>
    <DesktopOnly>
      <Flex
        backgroundColor={products[type].bg}
        style={{
          height: '165px',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: '1rem',
        }}
        direction="column"
      >
        {products[type].icon}
        <Text size={24} bold>
          {products[type].title}
        </Text>
        <Flex
          align="flex-end"
          style={{
            paddingBottom: 8,
          }}
        >
          <Text size={40} style={{ lineHeight: '100%', fontWeight: 700 }} bold>
            {products[type][frequency === 'monthly' ? 'amount' : 'yearAmount']}
          </Text>
          <Text size={12} style={{ lineHeight: '140%' }}>
            /month
          </Text>
        </Flex>
        <Text size={12}>
          {products[type].blurb1}
          <br />
          {products[type].blurb2}
        </Text>
      </Flex>
    </DesktopOnly>
    <MobileOnly>
      <Flex
        backgroundColor={products[type].bg}
        style={{
          height: '80px',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: '1rem',
        }}
        direction="column"
        align="center"
        justify="center"
      >
        <Text size={16} bold align="center">
          {products[type].title}
        </Text>
        <Text size={24} style={{ lineHeight: '100%', fontWeight: 700 }} bold>
          {products[type][frequency === 'monthly' ? 'amount' : 'yearAmount']}
        </Text>
        <Text size={10} style={{ lineHeight: '140%' }}>
          /month
        </Text>
      </Flex>
    </MobileOnly>
  </>
);

const PriceChart = () => {
  const [openTooltipCopy, _setOpenTooltipCopy] = useState();

  const setOpenTooltipCopy = v => {
    if (openTooltipCopy === v) {
      _setOpenTooltipCopy(null);
    } else {
      _setOpenTooltipCopy(v);
    }
  };

  const [frequency, setFrequency] = useState('yearly');

  return (
    <Flex direction="column" mx={[0]}>
      <Flex
        direction="row"
        align="center"
        justify="center"
        mt={['3rem', '4rem']}
        mb={['3rem', '4rem']}
      >
        <Flex
          as="button"
          onClick={() => setFrequency('monthly')}
          backgroundColor={frequency === 'monthly' ? colors.greenPea : colors.white}
          width={['200px', '250px']}
          style={{
            border: `1px solid ${colors.greenPea}`,
            borderRadius: 24,
            padding: '1rem 0',
          }}
          ml={[0, '40px']}
          justify="center"
          align="center"
        >
          <Text
            size={[16, 20]}
            bold
            color={frequency === 'monthly' ? colors.iceberg : colors.primary}
          >
            Billed monthly
          </Text>
        </Flex>
        <Flex
          as="button"
          onClick={() => setFrequency('yearly')}
          width={['200px', '250px']}
          backgroundColor={frequency === 'yearly' ? colors.greenPea : colors.white}
          style={{
            border: `1px solid ${colors.greenPea}`,
            borderRadius: 24,
            padding: '1rem 0',
          }}
          ml={['-38px', '-42px']}
          justify="center"
          align="center"
        >
          <Flex direction="row" align="center" justify="center">
            <Text
              size={[16, 20]}
              bold
              color={frequency === 'yearly' ? colors.iceberg : colors.primary}
            >
              Billed yearly
            </Text>
            <Flex
              backgroundColor={colors.greenLight}
              style={{ marginLeft: '0.5rem', borderRadius: 14, padding: '0.1rem 0.5rem' }}
            >
              <Text bold size={12} color={colors.primary}>
                30% off
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row">
        <Flex height={['1rem', 0]} width={['1rem', 0]} />
        <table
          style={{ width: '100%' }}
          onClick={() => {
            if (openTooltipCopy?.length) {
              setOpenTooltipCopy(null);
            }
          }}
        >
          <tbody>
            <tr>
              <td></td>
              <TableData>
                <ProductBlock type="free" frequency={frequency} />
              </TableData>
              <TableData>
                <ProductBlock type="pro" frequency={frequency} />
              </TableData>
              {/* <TableData>
              <ProductBlock type="max" frequency={frequency} />
            </TableData> */}
            </tr>
            <tr>
              <td
                style={{
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                  padding: '0.5rem',
                  backgroundColor: colors.greenPea,
                  paddingLeft: '1rem',
                }}
                colSpan="100%"
              >
                <Text size={20} bold color={colors.iceberg}>
                  Household size
                </Text>
              </td>
            </tr>
            <tr style={{ borderBottom: `1px solid ${colors.gray}` }}>
              <td>
                <Flex align="center" paddingLeft="1rem">
                  <Text bold size={[14, 16]}>
                    Accounts
                  </Text>
                  <CustomTooltip
                    openTooltipCopy={openTooltipCopy}
                    setOpenTooltipCopy={setOpenTooltipCopy}
                    copy="Open any combination of a personal, joint or teen accounts (coming soon)"
                  />
                </Flex>
              </td>
              <TableData>
                <DesktopOnly>
                  <TableRowContent text="3 accounts" color={colors.blue} />
                </DesktopOnly>
                <MobileOnly>
                  <TableRowContent text="3" color={colors.blue} />
                </MobileOnly>
              </TableData>
              <TableData>
                <DesktopOnly>
                  <TableRowContent text="4 accounts" color={colors.yellow} />
                </DesktopOnly>
                <MobileOnly>
                  <TableRowContent text="4" color={colors.yellow} />
                </MobileOnly>
              </TableData>
              {/* <TableData>
              <DesktopOnly>
                <TableRowContent text="5 accounts" color={colors.blue} />
              </DesktopOnly>
              <MobileOnly>
                <TableRowContent text="5" color={colors.blue} />
              </MobileOnly>
            </TableData> */}
            </tr>
            <tr>
              <td>
                <Flex align="center" paddingLeft="1rem">
                  <Text bold size={[14, 16]}>
                    Members
                  </Text>
                  <CustomTooltip
                    openTooltipCopy={openTooltipCopy}
                    setOpenTooltipCopy={setOpenTooltipCopy}
                    copy="Add any combination of co-owners, companions or kids 13 or older"
                  />
                </Flex>
              </td>
              <TableData>
                <DesktopOnly>
                  <TableRowContent text="3 members" color={colors.blue} />
                </DesktopOnly>
                <MobileOnly>
                  <TableRowContent text="3" color={colors.blue} />
                </MobileOnly>
              </TableData>
              <TableData>
                <DesktopOnly>
                  <TableRowContent text="4 members" color={colors.yellow} />
                </DesktopOnly>
                <MobileOnly>
                  <TableRowContent text="4" color={colors.yellow} />
                </MobileOnly>
              </TableData>
              {/* <TableData>
              <DesktopOnly>
                <TableRowContent text="5 members" color={colors.blue} />
              </DesktopOnly>
              <MobileOnly>
                <TableRowContent text="5" color={colors.blue} />
              </MobileOnly>
            </TableData> */}
            </tr>
            <tr>
              <td
                style={{
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                  padding: '0.5rem',
                  backgroundColor: colors.greenPea,
                  paddingLeft: '1rem',
                }}
                colSpan="100%"
              >
                <Text size={20} bold color={colors.iceberg}>
                  Family banking tools
                </Text>
              </td>
            </tr>
            <tr style={{ borderBottom: `1px solid ${colors.gray}` }}>
              <td>
                <Flex align="center" paddingLeft="1rem">
                  <Text bold size={[14, 16]}>
                    Debit cards
                  </Text>
                  <CustomTooltip
                    openTooltipCopy={openTooltipCopy}
                    setOpenTooltipCopy={setOpenTooltipCopy}
                    copy="Each member receives 1 physical + 1 virtual debit card for free"
                  />
                </Flex>
              </td>
              <TableData>
                <DesktopOnly>
                  <TableRowContent
                    text="free debit card per member"
                    color={colors.blue}
                  />
                </DesktopOnly>
                <MobileOnly>
                  <TableRowContent text={`1 free card\n/member`} color={colors.blue} />
                </MobileOnly>
              </TableData>
              <TableData>
                <DesktopOnly>
                  <TableRowContent
                    text="free debit card per member"
                    color={colors.yellow}
                  />
                </DesktopOnly>
                <MobileOnly>
                  <TableRowContent text={`1 free card\n/member`} color={colors.yellow} />
                </MobileOnly>
              </TableData>
              {/* <TableData>
              <DesktopOnly>
                <TableRowContent text="free debit card per member" color={colors.blue} />
              </DesktopOnly>
              <MobileOnly>
                <TableRowContent text={`1 free card\n/member`} color={colors.blue} />
              </MobileOnly>
            </TableData> */}
            </tr>
            {tools.map(tool => (
              <tr key={tool?.title} style={{ borderBottom: `1px solid ${colors.gray}` }}>
                <td>
                  <Flex direction="row" align="center" paddingLeft="1rem">
                    <Text maxWidth={['min-content', 'fit-content']} bold size={[14, 16]}>
                      {tool.title}
                    </Text>

                    <CustomTooltip
                      openTooltipCopy={openTooltipCopy}
                      setOpenTooltipCopy={setOpenTooltipCopy}
                      copy={tool.tooltip}
                    />
                  </Flex>
                </td>
                <TableData>
                  <TableRowContent
                    icon={
                      <RoundedCheck
                        bgColor={colors.diversityBlue}
                        checkColor={colors.primary}
                      />
                    }
                    color={colors.blue}
                  />
                </TableData>
                <TableData>
                  <TableRowContent
                    icon={
                      <RoundedCheck
                        bgColor={colors.yellowLight}
                        checkColor={colors.primary}
                      />
                    }
                    color={colors.yellow}
                  />
                </TableData>
                {/* <TableData>
                <TableRowContent
                  icon={
                    <RoundedCheck bgColor={colors.shared} checkColor={colors.white} />
                  }
                  color={colors.shared}
                />
              </TableData> */}
              </tr>
            ))}
            <tr>
              <td
                style={{
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                  padding: '0.5rem',
                  backgroundColor: colors.greenPea,
                  paddingLeft: '1rem',
                }}
                colSpan="100%"
              >
                <Text size={20} bold color={colors.iceberg}>
                  Premium features
                </Text>
              </td>
            </tr>
            <tr style={{ borderBottom: `1px solid ${colors.gray}` }}>
              <td>
                <Flex align="center" paddingLeft="1rem">
                  <Text bold size={[14, 16]}>
                    Automation+
                  </Text>
                  <CustomTooltip
                    openTooltipCopy={openTooltipCopy}
                    setOpenTooltipCopy={setOpenTooltipCopy}
                    copy="Put your finances on auto-pilot using custom automations for bills and goals"
                  />
                </Flex>
              </td>
              <TableData>
                <TableRowContent text="-" color={colors.blue} />
              </TableData>
              <TableData>
                <TableRowContent
                  icon={
                    <RoundedCheck
                      bgColor={colors.yellowLight}
                      checkColor={colors.primary}
                    />
                  }
                  color={colors.yellow}
                />
              </TableData>
              {/* <TableData>
              <TableRowContent
                icon={<RoundedCheck bgColor={colors.shared} checkColor={colors.white} />}
                color={colors.shared}
              />
            </TableData> */}
            </tr>
            <tr style={{ borderBottom: `1px solid ${colors.gray}` }}>
              <td>
                <Flex align="center" paddingLeft="1rem">
                  <Text bold size={[14, 16]}>
                    Cards+
                  </Text>
                  <CustomTooltip
                    openTooltipCopy={openTooltipCopy}
                    setOpenTooltipCopy={setOpenTooltipCopy}
                    copy="Unlock more virtual cards and card controls like spend limits"
                  />
                </Flex>
              </td>
              <TableData>
                <TableRowContent text="-" color={colors.blue} />
              </TableData>
              <TableData>
                <TableRowContent
                  icon={
                    <RoundedCheck
                      bgColor={colors.yellowLight}
                      checkColor={colors.primary}
                    />
                  }
                  color={colors.yellow}
                />
              </TableData>
              {/* <TableData>
              <TableRowContent
                icon={<RoundedCheck bgColor={colors.shared} checkColor={colors.white} />}
                color={colors.shared}
              />
            </TableData> */}
            </tr>
            {/* <tr style={{ borderBottom: `1px solid ${colors.gray}` }}>
            <td>
              <Flex align="center" paddingLeft="1rem">
                <Text bold size={[14, 16]}>
                  Vault+
                </Text>
                <CustomTooltip
                  openTooltipCopy={openTooltipCopy}
                  setOpenTooltipCopy={setOpenTooltipCopy}
                  copy="Safe and secure storage for your family's important digital files (coming soon)"
                />
              </Flex>
            </td>
            <TableData>
              <TableRowContent text="-" color={colors.blue} />
            </TableData>
            <TableData>
              <TableRowContent text="-" color={colors.yellow} />
            </TableData> */}
            {/* <TableData>
              <TableRowContent
                icon={<RoundedCheck bgColor={colors.shared} checkColor={colors.white} />}
                color={colors.shared}
              />
            </TableData> */}
            {/* </tr> */}
          </tbody>
        </table>
      </Flex>
      <Flex style={{ marginTop: '1rem' }} paddingLeft="1rem">
        <Text size={12}>
          * support for checks includes check writing and mobile check deposit; features
          may have limited availability; fees may apply
          <br />
          ** learn more about how to qualify for Zeta’s VIP program{' '}
          <a
            style={{ color: colors.greenPea }}
            href="https://www.askzeta.com/help/zeta-vip/what-is-zetas-vip-tier?from_search=125533268"
          >
            here
          </a>
        </Text>
      </Flex>
    </Flex>
  );
};

export default PriceChart;
