import React from 'react';
import { Flex, Box, Image } from '@chakra-ui/core';

import Layout from '../components/layout';
import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH, APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';
import { H1, H2, H3, H4, Text } from '@typography';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';
import { cloudfrontBaseUrl } from '../lib/consts';
import { colors } from '@theme';
import { Tag } from '@zeta';
import { Metadata, PriceChart } from '@zetaplus';

const zetaPlusOfferings = [
  {
    title: 'Automation+',
    price: undefined,
    color: colors.peach,
    desktopImage: `${cloudfrontBaseUrl}/zeta-plus/desktop-paycheck-golden.png`,
    mobileImage: `${cloudfrontBaseUrl}/zeta-plus/mobile-automation.png`,
    body:
      "Put your finances on autopilot. Use custom rules to set aside funds for your bills or goals. We'll even automate sending money to your external accounts. The only thing you need to worry about is getting paid.",
    alt: 'Automation+ sample',
  },
  {
    title: 'Cards+',
    price: undefined,
    color: colors.yellowLight,
    desktopImage: `${cloudfrontBaseUrl}/zeta-plus/desktop-cards-golden.png`,
    mobileImage: `${cloudfrontBaseUrl}/zeta-plus/mobile-cards.png`,
    body:
      "Unlock additional virtual cards to help you better manage your spending, privacy and security. Each card comes with custom card controls that let you set spending limits. If a transaction is over the limit, or doesn't meet the requirements you set, we'll automatically decline it. Coming soon you will be able create specific merchant controls.",
    alt: 'Cards+ sample',
  },
];

const ZetaPlus = () => {
  return (
    <Layout noRootContainer mode="home" newNav>
      <Metadata />
      <ContentContainer>
        <Flex
          className="zeta-plus-overview"
          width="100%"
          justifyContent="center"
          direction="column"
          pt={[0, 16]}
        >
          <Flex
            className="zeta-plus-hero"
            pt={[24, 18]}
            align="center"
            direction="column"
          >
            <DesktopOnly>
              <H1 center>Introducing Zeta+</H1>

              <H4 position="relative" zIndex={3} center as="h2" mt={8}>
                Premium feature add-ons to help your family thrive
              </H4>
              <div style={{ minHeight: '500px' }}>
                <Image
                  position="relative"
                  ml="42px"
                  mt="-150px"
                  src={`${cloudfrontBaseUrl}/zeta-plus/desktop-hero-4v2.png`}
                  alt="zeta in app premium features"
                />
              </div>
            </DesktopOnly>

            <MobileOnly>
              <H1>
                Introducing
                <br />
                Zeta+
              </H1>
              <H4 center bold as="h2" pt="22px">
                Premium feature add-ons
                <br />
                to help your family thrive
              </H4>

              <div
                style={{
                  marginTop: '12px',
                }}
              >
                <Image
                  zIndex={0}
                  src={`${cloudfrontBaseUrl}/zeta-plus/mobile-hero-4v2.png`}
                  alt="zeta in app premium features"
                />
              </div>
            </MobileOnly>
          </Flex>
        </Flex>
      </ContentContainer>
      <Flex direction="column" className="premium-offers" bg={colors.iceberg}>
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Flex
            className="offers-heading"
            pt={['40px', '56px']}
            align="center"
            direction="column"
          >
            <H2>Supercharge your account</H2>

            <Box pt={['8px', '22px']} width={['294px', '544px']}>
              <H4 center as="p">
                While core Zeta accounts remain free, Zeta+ lets you level up so your
                account does more for you:
              </H4>
            </Box>
          </Flex>
          <PriceChart />
        </ContentContainer>
      </Flex>
      <Flex direction="column" className="premium-offers" bg={colors.iceberg}>
        <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
          <Flex
            className="offers-heading"
            pt={['40px', '56px']}
            align="center"
            direction="column"
          >
            <DesktopOnly>
              <H2>Meet our premium features</H2>
            </DesktopOnly>

            <MobileOnly>
              <H2>
                Meet our
                <br />
                premium features
              </H2>
            </MobileOnly>

            <Box pt={['8px', '22px']} width={['294px', '544px']}>
              <H4 center as="p">
                Here are the deets on some of
                <br />
                our premium features:
              </H4>
            </Box>
          </Flex>

          <Box
            pt={['54px', '40px']}
            px={['18px', '0px']}
            pb={['60px', '120px']}
            className="offers-list"
          >
            {zetaPlusOfferings.map((offering, idx) => (
              <Flex
                key={idx}
                className={`${offering.title}`}
                bg={offering.color}
                direction={['column', 'row']}
                justifyContent={['space-between']}
                height={['auto', '370px']}
                width="100%"
                overflow="hidden"
                mt={idx !== 0 ? '40px' : 0}
                sx={{
                  border: `1px solid #000000`,
                  borderRadius: ['15px', '32px'],
                }}
                pb={['24px', 0]}
              >
                <Flex
                  className={`${offering.title}-body`}
                  direction="column"
                  justifyContent="center"
                  pb={[0, '24px']}
                >
                  <Flex justify="center">
                    <MobileOnly>
                      <Image src={offering.mobileImage} alt={offering.alt} />
                    </MobileOnly>
                  </Flex>

                  {offering.tag?.length > 0 && (
                    <Box mt={['-40px', '40px']} pl={'27px'}>
                      <Tag
                        color="white"
                        borderColor="black"
                        width={[offering.tagWidth.mobile, offering.tagWidth.desktop]}
                        text={offering.tag}
                      />
                    </Box>
                  )}
                  <Box pl={'27px'} pt="16px">
                    <DesktopOnly>
                      <H3 style={{ color: colors.primary }}>{offering.title}</H3>
                      {offering.price && <Text bold>{offering.price}</Text>}
                    </DesktopOnly>

                    <MobileOnly>
                      <H3 style={{ color: colors.primary }} noAlign>
                        {offering.title}
                      </H3>
                      {offering.price && <Text bold>{offering.price}</Text>}
                    </MobileOnly>
                  </Box>
                  <Box
                    pl="27px"
                    pt="24px"
                    width={[dynamicSizes.mobileBodyWidth(idx), '400px']}
                  >
                    <Text>{offering.body}</Text>
                  </Box>
                </Flex>

                <DesktopOnly>
                  <Flex
                    style={{
                      height: '100%',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Image
                      mr={idx === 1 && '-10px'}
                      src={offering.desktopImage}
                      alt={offering.alt}
                      style={{ height: '100%', marginLeft: 'auto' }}
                    />
                  </Flex>
                </DesktopOnly>
              </Flex>
            ))}
          </Box>
        </ContentContainer>
      </Flex>
    </Layout>
  );
};

const dynamicSizes = {
  mobileTagMarginTop: idx => {
    if (idx === 1) {
      return '-100px';
    }

    if (idx === 2) {
      return '-50px';
    }

    if (idx === 3) {
      return '-10px';
    }
  },
  mobileBodyWidth: idx => {
    if (idx === 0) {
      return '315px';
    }

    if (idx === 1) {
      return '290px';
    }

    if (idx === 2 || idx === 3) {
      return '300px';
    }
  },
};

export default ZetaPlus;
